<template>
  <div id="app">
    <router-view />
    <!-- <audio ref="page">
      <source
        src="http://v2.shenbiande.com/6c99c4devodcq1500004846/a088e1b75576678020665957536/StssRJDcF1gA.mp3"
        type="audio/mp3"
      />
    </audio>
    <audio ref="allRight">
      <source
        src="http://v2.shenbiande.com/6c99c4devodcq1500004846/df3b344f5576678020666316376/Nzd6vbAxmtUA.mp3"
        type="audio/mp3"
      />
    </audio> -->
    <audio id="Jaudio" :loop="loop"></audio>
  </div>
</template>
<script>
function bgm(type, src) {
  var audio = document.getElementById("Jaudio");
  audio.setAttribute("src", src);
  if (type == "play") {
    audio.play();
  } else {
    audio.pause();
  }
}
// 这里是AudioContext给安卓用
window.AudioContext =
  window.AudioContext ||
  window.webkitAudioContext ||
  window.mozAudioContext ||
  window.msAudioContext;

var context = new window.AudioContext();
var source = null;
var audioBuffer = null;

function stopSound() {
  if (source) {
    source.stop(0); //立即停止
  }
}

function playSound(type) {
  const that = this;
  source = context.createBufferSource();
  source.buffer = audioBuffer;
  if (type == "loop") {
    source.loop = true; //循环播放
  } else {
    source.loop = false;
  }
  source.connect(context.destination);
  source.start(0); //立即播放
  // if (!/iphone/.test(navigator.userAgent.toLowerCase())) {
  //   source.start(0); //立即播放
  // }
}

function initSound(arrayBuffer, type) {
  context.decodeAudioData(
    arrayBuffer,
    function (buffer) {
      //解码成功时的回调函数
      audioBuffer = buffer;
      playSound(type);
    },
    function (e) {
      //解码出错时的回调函数
      console.log("Error decoding file", e);
    }
  );
}

function loadAudioFile(url, type) {
  var xhr = new XMLHttpRequest(); //通过XHR下载音频文件
  xhr.open("GET", url, true);
  xhr.responseType = "arraybuffer";
  xhr.onload = function (e) {
    //下载完成
    initSound(this.response, type);
  };
  xhr.send();
}

export default {
  data() {
    return {
      page: "https://1317068014.vod2.myqcloud.com/ea1c79e8vodsh1317068014/bd444f9d1397757893463555287/K58GoNZP9dEA.mp3",
      allRight:
        "https://1317068014.vod2.myqcloud.com/ea1c79e8vodsh1317068014/c201e7661397757893463767786/gKqBAfLmYLYA.mp3",
      loop: true,
    };
  },
  methods: {
    play(url,type) {
      // 安卓，加载并播放
      if (!/iphone/.test(navigator.userAgent.toLowerCase())) {
        loadAudioFile(url, type);
      } else {
        bgm("play", url);
      }
    },
    stop(url) {
      if (!/iphone/.test(navigator.userAgent.toLowerCase())) {
        stopSound();
      } else {
        bgm("pause", url);
      }
    },
  },
  mounted() {
    const that = this;
    let url;
    let loop;
    if (this.$route.fullPath == "/success?level=4") {
      url = this.allRight;
      loop = "fail";
      this.loop = false;
    } else {
      url = this.page;
      loop = "loop";
      this.loop = true;
    }

    if (!/iphone/.test(navigator.userAgent.toLowerCase())) {
      stopSound();
      loadAudioFile(url, loop);
    } else {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          bgm("play", url);
        },
        false
      );
    }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath == "/list?level=2" || to.fullPath == "/success?level=4") {
        this.stop(this.page);
        if (to.fullPath == "/success?level=4") {
          this.loop = false;
          this.play(this.allRight, "fail");
        }
      }
      if (from.fullPath == "/success?level=4") {
        this.stop(this.allRight);
        this.loop = true;
        this.play(this.page, "loop");
      }
      if (from.fullPath == "/list?level=2") {
        this.loop = true;
        this.play(this.page, "loop");
      }
    },
  },
};
</script>
<style lang="less">
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
